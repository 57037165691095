<template>
  <div>
    <h1 class="card__heading title">Verzoekjes</h1>

    <!-- Request count and filters -->
    <div class="filter-wrapper">
      <div class="badge-wrapper">
        <el-badge :value="totalRequests" style="margin-right: 2.5rem"
          ><el-tag size="small">Totaal</el-tag></el-badge
        >
        <el-badge
          v-if="filtersApplied"
          :value="totalTableData"
          style="margin-right: 2.5rem"
          ><el-tag effect="dark" size="small">Filter</el-tag></el-badge
        >
      </div>
      <el-switch
        v-model="karaokeFilter"
        active-icon-class="el-icon-mic"
        style="margin-right: 2.5rem"
        :width="65"
      ></el-switch>
      <el-switch
        v-model="imageFilter"
        active-icon-class="el-icon-camera-solid"
        style="margin-right: 2.5rem"
        :width="65"
      ></el-switch>
    </div>

    <el-table
      :data="paginatedData"
      style="width: 100%; min-height: 30rem"
      row-class-name="table-row"
      size="small"
      @row-click="handleRowClick"
      @expand-change="handleExpandChange"
      ref="table"
      row-key="_id"
    >
      <!-- No content slot -->
      <template #empty>
        <p>Geen verzoekjes gevonden</p>
        <el-button
          v-if="totalRequests === 0 && $route.path === '/admin'"
          type="primary"
          size="mini"
          @click="goToDummyProfiles"
          >Voeg een dummy toe</el-button
        >
      </template>

      <!-- Favorite button -->
      <el-table-column width="135">
        <template #default="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete($event, scope.$index, scope.row)"
          >
            <i class="el-icon-delete" />
          </el-button>
          <el-button
            size="mini"
            @click="handleFavorite($event, scope.$index, scope.row)"
          >
            <i
              :class="`el-icon-star-${scope.row.favorite ? 'on' : 'off'}`"
              :style="scope.row.favorite ? 'color: #cc0e22;' : ''"
            />
          </el-button>
        </template>
      </el-table-column>

      <!-- Active status  -->
      <el-table-column width="25px" className="active-column">
        <template #default="scope">
          <div
            :class="
              hasScreenState(scope.row)
                ? 'activeStatus activeStatus--active'
                : 'activeStatus'
            "
          ></div>
        </template>
      </el-table-column>

      <!-- Expanded column -->
      <el-table-column type="expand" width="1px">
        <template #default="props">
          <el-row>
            <!-- Unaccepted requests -->
            <el-col v-if="!hasScreenState(props.row)" :span="12">
              <el-switch
                :disabled="!props.row.karaoke"
                v-model="acceptedFields[props.row._id].karaoke"
                active-icon-class="el-icon-mic"
                style="margin-right: 2.5rem"
                :width="65"
              ></el-switch>
              <el-switch
                :disabled="!props.row.image_url"
                v-model="acceptedFields[props.row._id].image"
                active-icon-class="el-icon-camera-solid"
                style="margin-right: 2.5rem"
                :width="65"
              ></el-switch>
              <div class="btn-container">
                <el-button
                  plain
                  type="primary"
                  icon="el-icon-check"
                  style="height: 15rem; width: calc(130px + 5rem)"
                  @click="handleAccept($event, props.$index, props.row)"
                  >Accepteren</el-button
                >
              </div>
            </el-col>

            <!-- Accepted requests -->
            <el-col v-else-if="hasScreenState(props.row)" :span="12">
              <el-radio-group
                v-model="acceptedRequest.screenStatus"
                @change="handleAcceptedRequestScreenStatus"
                :style="{
                  '--num-of-radio-buttons': acceptedRequest.showimage ? 3 : 2,
                }"
              >
                <el-radio-button
                  label="Startscherm"
                  class="largeRadioButton"
                ></el-radio-button>
                <el-radio-button
                  label="Onthullen"
                  class="largeRadioButton"
                ></el-radio-button>
                <el-radio-button
                  v-if="acceptedRequest.showimage"
                  label="Foto"
                  :disabled="!acceptedRequest.imageReady"
                  class="largeRadioButton"
                >
                  <i
                    v-if="
                      !acceptedRequest.imageReady && !acceptedRequest.imageError
                    "
                    class="el-icon-loading"
                  ></i>
                  <span v-else>
                    Foto
                    <i
                      v-if="acceptedRequest.imageError"
                      class="el-icon-error"
                      style="color: #cc0e22; margin-left: 10px"
                    />
                  </span>
                </el-radio-button>
              </el-radio-group>
            </el-col>

            <el-col :span="12">
              <el-image
                v-if="props.row.image_url"
                :src="`${$store.getters.getConstant('API_URI')}/${
                  props.row.thumb_url
                }`"
                style="width: 40rem; height: 27rem"
                fit="contain"
              >
                <template #placeholder>
                  <div class="image-slot">
                    <i class="el-icon-loading"></i>
                  </div>
                </template>
                <template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                    <p>Kan foto niet laden</p>
                  </div>
                </template>
              </el-image>
            </el-col>
          </el-row>
        </template>
      </el-table-column>

      <!-- Indicators -->
      <el-table-column width="70px">
        <template #default="scope">
          <i
            v-if="scope.row.karaoke"
            class="el-icon-mic"
            style="margin-right: 5px; vertical-align: -2px"
          ></i>
          <i
            v-if="scope.row.image_url"
            class="el-icon-camera-solid"
            style="margin-right: 5px; vertical-align: -2px"
          ></i>
        </template>
      </el-table-column>

      <!-- Request data -->
      <el-table-column
        prop="song"
        label="Titel"
        min-width="200px"
      ></el-table-column>
      <el-table-column
        prop="artist"
        label="Artiest"
        min-width="120px"
      ></el-table-column>
      <el-table-column label="Naam" min-width="100px">
        <template #default="scope">
          <p>
            {{ scope.row.requester_firstname }}
            {{ scope.row.requester_lastname }}
          </p>
        </template>
      </el-table-column>

      <!-- Action buttons -->
      <el-table-column width="75px">
        <template #default="scope">
          <el-button
            v-if="userRole === 'admin' && $route.path !== '/dj'"
            size="mini"
            type="info"
            @click="handleDJ($event, scope.$index, scope.row)"
            ><i class="el-icon-service"></i
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Pagination -->
    <div class="pagination-wrapper">
      <!-- Page size select -->
      <el-select v-model="pageSize" style="width: 75px">
        <el-option
          v-for="size in pageSizes"
          :key="size"
          :value="size"
          :label="size"
        ></el-option>
      </el-select>

      <!-- Pagination buttons-->
      <!-- eslint-disable -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalTableData"
        :page-size="pageSize"
        v-model:current-page="currentPage"
      >
      </el-pagination>
      <!-- eslint-enable -->

      <!-- Sync button & Delete all button -->
      <div>
        <el-button
          circle
          icon="el-icon-refresh-left"
          type="warning"
          size="small"
          @click="handleRefresh"
        />

        <el-button
          circle
          icon="el-icon-delete"
          type="danger"
          size="small"
          :disabled="!hasNonScreenRequests"
          @click="handleDeleteAllRequests"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElImage,
  ElSwitch,
  ElPagination,
  ElBadge,
  ElTag,
  ElSelect,
  ElOption,
  ElRadioGroup,
  ElRadioButton,
  ElMessageBox,
} from "element-plus";

import fetchMixins from "../mixins/ajax";
import generalTableMixins from "../mixins/tables";

export default {
  components: {
    ElTableColumn,
    ElTable,
    ElImage,
    ElSwitch,
    ElPagination,
    ElBadge,
    ElTag,
    ElSelect,
    ElOption,
    ElRadioGroup,
    ElRadioButton,
  },
  mixins: [fetchMixins, generalTableMixins],
  inject: ["io"],
  data() {
    return {
      tableData: [],
      acceptedRequest: null,
      acceptedFields: [], // stores for each request if it will accept karaoke and image (key = _id)
      pageSize: 100,
      pageSizes: [5, 10, 25, 50, 100, 250],
    };
  },
  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    filters() {
      return this.$store.getters["requestFilters"];
    },
    favFilter: {
      get() {
        return this.filters.favorites;
      },
      set(value) {
        this.$store.commit("setFilter", { type: "favorites", value });
      },
    },
    karaokeFilter: {
      get() {
        return this.filters.karaokes;
      },
      set(value) {
        this.$store.commit("setFilter", { type: "karaokes", value });
      },
    },
    imageFilter: {
      get() {
        return this.filters.images;
      },
      set(value) {
        this.$store.commit("setFilter", { type: "images", value });
      },
    },
    filteredData() {
      let filteredData;

      // Filter favorites if necessary
      filteredData = this.tableData.filter((data) => {
        return !this.filters.favorites || data.favorite;
      });

      // Filter karaokes if necessary
      filteredData = filteredData.filter((data) => {
        return !this.filters.karaokes || data.karaoke;
      });

      // Filter images if necessary
      filteredData = filteredData.filter((data) => {
        return !this.filters.images || data.image_url;
      });

      return filteredData;
    },
    paginatedData() {
      return this.filteredData.slice(this.fromIndex, this.toIndex);
    },
    totalRequests() {
      return this.tableData.length;
    },
    // Return number of filtered requests
    totalTableData() {
      return this.filteredData.length;
    },
    filtersApplied() {
      return (
        this.filters.favorites || this.filters.karaokes || this.filters.images
      );
    },
    hasNonScreenRequests() {
      return !!this.tableData?.filter((req) => req.state !== "screen").length;
    },
    // Returns true if a specified request has state of 'screen'.
    // @param row / request
    hasScreenState() {
      return (row) => {
        if (
          this.acceptedRequest &&
          this.acceptedRequest._id === row._id &&
          this.acceptedRequest.state === "screen"
        ) {
          return true;
        }
        if (this.acceptedRequest === null && row.state === "screen") {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    goToDummyProfiles() {
      this.$store.commit("setDashboardActiveTab", "dummy");
    },
    // Fetch requests to populate the data table
    async fetchRequests() {
      const querystringObject = {};

      if (this.userRole === "dj" || this.$route.path === "/dj") {
        querystringObject.state = "dj&state=screen";
        querystringObject.allowDj = true;
      } else if (this.userRole === "admin") {
        querystringObject.state = "new&state=screen";
      } else {
        return this.$router.push("/login");
      }

      const response = await this.fetchData(
        null,
        "request",
        "GET",
        querystringObject,
        null
      );

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "Er is een fout opgetreden bij het laden van de verzoekjes. Probeer het later nog eens."
        );
        return;
      }

      // Add 'accept' field to each request object in the response array
      response.data.forEach((request) => {
        this.addAcceptFields(request);
      });

      this.setAcceptedRequest(response.data);

      this.tableData = response.data;
    },
    // Handler for clicking DJ button
    async handleDJ(ev, _index, row) {
      ev.stopPropagation();

      const payload = {
        state: "dj",
      };

      const response = await this.fetchData(
        null,
        `request/${row._id}`,
        "PUT",
        null,
        payload
      );

      this.resetRowExpansionStatus();

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "Het verzoekje kon niet naar de DJ worden gestuurd. Probeer het later nog eens."
        );
        return;
      }

      // Remove item form table
      this.removeRequestFromTable(row._id);
    },
    // Handler for clicking Delete button
    async handleDelete(ev, _index, row) {
      ev.stopPropagation();

      const payload = {
        state: "done",
      };

      const response = await this.fetchData(
        null,
        `request/${row._id}`,
        "PUT",
        null,
        payload
      );

      this.resetRowExpansionStatus();

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "Het verzoekje kon niet worden verwijderd. Probeer het later nog eens."
        );
        return;
      }

      // Emit event if the request has a state of 'screen'
      if (this.hasScreenState(row)) {
        this.io.emit("screen-clear-request");
      }

      // // Remove item form table
      this.removeRequestFromTable(row._id);
    },
    // Handler for clicking Delete all DJ requests button
    async handleDeleteAllRequests() {
      const message =
        this.$route.path === "/dj"
          ? "Wil je alle DJ verzoekjes uit je lijst verwijderen?"
          : "Wil je alle nieuwe verzoekjes uit je lijst verwijderen?";
      const confirmed = await ElMessageBox.confirm(
        message,
        "Alles verwijderen",
        {
          confirmButtonText: "Verwijderen",
          cancelButtonClass: "Annuleren",
          type: "warning",
        }
      )
        .then(() => true)
        .catch(() => false);

      if (!confirmed) {
        return;
      }

      const stateToDelete = this.$route.path === "/dj" ? "dj" : "new";

      const ids =
        this.tableData
          ?.filter((request) => request.state === stateToDelete)
          .map((request) => request._id) || [];
      const payload = {
        ids,
        state: "done",
      };

      const response = await this.fetchData(
        null,
        `request`,
        "PUT",
        null,
        payload
      );

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "Kon de verzoekjes niet verwijderen. Probeer het later nog eens."
        );
        return;
      }

      this.tableData =
        this.tableData?.filter((request) => !ids.includes(request._id)) || [];
    },
    // Handler for clicking Favorite button
    async handleFavorite(ev, _index, row) {
      ev.stopPropagation();

      const payload = {
        favorite: !row.favorite,
      };

      const response = await this.fetchData(
        null,
        `request/${row._id}`,
        "PUT",
        null,
        payload
      );

      this.resetRowExpansionStatus();

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message("Er is een fout opgetreden. Probeer het later nog eens.");
        return;
      }

      row.favorite = !row.favorite;
    },
    async handleRefresh() {
      await this.fetchRequests().catch(() => {});
      setTimeout(() => {
        this.io.emit("dashboard-reloaded");
      }, 1000);
    },
    // Handler for accepting a request (updating state to 'screen')
    async handleAccept(ev, index, row) {
      ev.stopPropagation();

      const payload = {
        state: "screen",
        showimage: this.acceptedFields[row._id].image,
        karaoke: this.acceptedFields[row._id].karaoke,
      };

      const response = await this.fetchData(
        null,
        `request/${row._id}`,
        "PUT",
        null,
        payload
      );

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "Het verzoekje kon niet worden geaccepteerd. Probeer het later nog eens."
        );
        return;
      }

      // Prevent current row from remaining closed after change of table data.
      if (this.acceptedRequest) {
        this.resetRowExpansionStatus();
      }

      // Set state of the request in tableData
      row.state = "screen";

      // !! this.acceptedRequest value is not set here, but in request-state-change event handler
    },
    // Handler for @change of checkboxes (Startscherm, onthullen, foto)
    handleAcceptedRequestScreenStatus(status) {
      if (status === "Startscherm") {
        return this.io.emit("screen-clear-request", status);
      }
      if (status === "Foto" || status === "Onthullen") {
        return this.io.emit("screen-reveal-request", status);
      }
    },
    // Store if a request will be accepted with karaoke and/or image option.
    addAcceptFields(request) {
      this.acceptedFields[request._id] = {};
      this.acceptedFields[request._id].karaoke = !!request.karaoke;
      this.acceptedFields[request._id].image = !!request.image_url;
    },
    // Take request with state 'screen' from requests array and set acceptedRequest value
    setAcceptedRequest(requestArray) {
      const acceptedRequest = requestArray.find(
        (request) => request.state === "screen"
      );
      if (acceptedRequest) {
        acceptedRequest.screenStatus = "Startscherm";
        this.acceptedRequest = JSON.parse(JSON.stringify(acceptedRequest));
      }
    },
    // Remove request from data table by its ID
    removeRequestFromTable(id) {
      const indexToRemove = this.tableData.findIndex(
        (request) => request._id === id
      );

      if (indexToRemove > -1) {
        this.tableData.splice(indexToRemove, 1);
      }
    },
    resetRowExpansionStatus() {
      if (this.expandedRow) {
        setTimeout(() => {
          this.$refs.table.toggleRowExpansion(this.expandedRow, true);
        }, 0);
      }
    },
  },

  mounted() {
    this.fetchRequests();
    // Listeners for admin
    if (this.userRole === "admin" && this.$route.path === "/admin") {
      // Handler for just created requests
      this.io.on("request-create", (payload) => {
        this.addAcceptFields(payload.request);
        this.tableData.push(payload.request);
        this.resetRowExpansionStatus();
      });

      // Handlers to sync multiple admin user's actions
      this.io.on("request-state-change", (payload) => {
        if (payload.state === "dj" || payload.state === "done") {
          this.removeRequestFromTable(payload.request._id);
          this.resetRowExpansionStatus();
        }
        if (payload.state === "screen") {
          this.acceptedRequest = payload.request;
          this.acceptedRequest.screenStatus = "Startscherm";
        }
      });

      this.io.on("screen-reveal-request", (value) => {
        this.acceptedRequest.screenStatus = value;
      });
      this.io.on("screen-clear-request", (value) => {
        if (value === "Startscherm") {
          this.acceptedRequest.screenStatus = value;
        }
      });
    }

    // Listeners for DJ
    if (
      (this.userRole === "dj" || this.userRole === "admin") &&
      this.$route.path === "/dj"
    ) {
      this.io.on("request-state-change", (payload) => {
        if (payload.state === "dj") {
          this.addAcceptFields(payload.request);
          this.tableData.push(payload.request);
        }
        if (payload.state === "screen") {
          this.acceptedRequest = payload.request;
          this.acceptedRequest.screenStatus = "Startscherm";
        }
      });
    }

    this.io.on("screen-image-ready", () => {
      if (this.acceptedRequest) {
        this.acceptedRequest.imageReady = true;
        this.acceptedRequest.imageError = false;
      }
    });
    this.io.on("screen-image-error", () => {
      if (this.acceptedRequest) {
        this.acceptedRequest.imageError = true;
        this.acceptedRequest.imageReady = false;
      }
    });

    this.io.on("screen-change", (value) => {
      if (!this.acceptedRequest) {
        return;
      }

      if (value === "screen-portal-reloaded") {
        this.acceptedRequest.imageReady = false;
        this.acceptedRequest.screenStatus = "Startscherm";
      } else {
        this.acceptedRequest.screenStatus = value;
      }
    });
  },
  unmounted() {
    this.io.off();
  },
};
</script>

<style lang="scss" scoped>
.largeRadioButton {
  max-width: calc(100% / var(--num-of-radio-buttons, 1));
  & ::v-deep span {
    height: 14rem;
    width: 14rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
}
.image-slot {
  height: 20rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eee;

  & ::v-deep i {
    font-size: 5rem;
  }
}

::v-deep .el-table__expand-icon {
  display: none;
}

::v-deep .table-row {
  cursor: pointer;
  color: $color-black;
  font-size: 18px;
}
::v-deep .cell {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.btn-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}

.filter-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.badge-wrapper {
  margin-right: auto;
}

::v-deep .active-column > .cell {
  padding: 0;
}

.activeStatus {
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border-radius: 50%;
  margin: 0 auto;

  &--active {
    background-color: #67c23a;
  }
}
</style>
