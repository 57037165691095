/**
 * Mixin for datatables
 * Handling expanded rows & pagination
 */
export default {
  data() {
    return {
      expandedRow: null,
      pageSize: 5,
      pageSizes: [5, 10, 25, 50, 100],
      currentPage: 1,
    };
  },
  computed: {
    fromIndex() {
      return (this.currentPage - 1) * this.pageSize;
    },
    toIndex() {
      const highBound = this.fromIndex + this.pageSize;

      return highBound > this.totalTableData ? this.totalTableData : highBound;
    },
  },
  methods: {
    handleRowClick(row) {
      if (this.expandedRow && this.expandedRow !== row) {
        this.$refs.table.toggleRowExpansion(this.expandedRow, false);
      }
      this.expandedRow = row;
      this.$refs.table.toggleRowExpansion(row);
    },
    handleExpandChange(row, expandedRows) {
      // Close currently expanded row if necessary
      if (this.expandedRow !== row) {
        this.$refs.table.toggleRowExpansion(this.expandedRow, false);
      }
      // Set current expanded row prop if necessary
      if (expandedRows.includes(row)) {
        return (this.expandedRow = row);
      }
      // Set current expanded row prop to null if necessary
      if (!expandedRows.length) {
        return (this.expandedRow = null);
      }
    },
  },
};
