<template>
  <div>
    <el-tabs type="border-card" style="max-width: 140rem; margin: 0 auto">
      <el-tab-pane label="Verzoekjes">
        <requests-table></requests-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { io } from "socket.io-client";

import { ElTabs, ElTabPane } from "element-plus";
import RequestsTable from "../components/RequestsTable";

export default {
  name: "AdminDashboard",
  components: {
    RequestsTable,

    ElTabPane,
    ElTabs,
  },
  provide() {
    return {
      io: this.io,
    };
  },
  beforeCreate() {
    const ROOT = this.$store.getters.getConstant("SOCKET_IO_ROOT");
    const PATH = this.$store.getters.getConstant("SOCKET_IO_PATH");
    this.io = io(ROOT, {
      path: PATH,
    });
    this.io.on("determineOffset", () => {}); // A first event is needed to make recovery work
  },
  mounted() {
    // Emit event so ScreenPortal page can respond
    setTimeout(() => {
      this.io.emit("dashboard-reloaded");
    }, 1000);

    // Sync activeScreen with ScreenPortal when ScreenPortal page is reloaded
    this.io.on("screen-change", (value) => {
      if (value === "screen-portal-reloaded") {
        this.$store.commit("setActiveScreen", "request");
      } else {
        this.$store.commit("setActiveScreen", value);
      }
    });
  },
  unmounted() {
    this.io.off();
  },
};
</script>

<style lang="scss" scoped></style>
